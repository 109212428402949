import circle from "./modules/circle";
import linearAnimations from "./modules/linearAnimations";
import send from "./modules/send";
import validate from "./modules/validate";
window.addEventListener("load", () => {

  let mm = gsap.matchMedia();
  mm.add(
    {
      isMobile: "(max-width: 576px)",
      isDesktop: "(min-width: 577px)",
    },
    (context) => {
      let { isMobile, isDesktop } = context.conditions;

      if (isDesktop) {
        const numbersTl = gsap.timeline({
          scrollTrigger: {
            trigger: '.who-we__numbers',
            start: 'top 85%',
            end: 'center center',
            scrub: 3
          },
          defaults: {
            ease: "power1.out",
          }
        })
        
        numbersTl.to('.who-we__num:nth-of-type(1)', {borderColor: '#b4141e'})
          .to('.who-we__item:nth-of-type(1) span', {color: '#b4141e'}, '<')
          .to('.who-we__line:nth-of-type(2)', {backgroundPosition: '-100% 0'})
          .to('.who-we__num:nth-of-type(3)', {borderColor: '#b4141e'})
          .to('.who-we__item:nth-of-type(2) span', {color: '#b4141e'}, '<')
          .to('.who-we__line:nth-of-type(4)', {backgroundPosition: '-100% 0'})
          .to('.who-we__num:nth-of-type(5)', {borderColor: '#b4141e'})
          .to('.who-we__item:nth-of-type(3) span', {color: '#b4141e'}, '<')
      }

    })



  const headerTl = gsap.timeline({
    defaults: {
      duration: 1.5,
      ease: "power3.out",
    },
    paused: true
  })
  headerTl
    .fromTo('.header__title', {opacity: 0, "--firstStep": '0%', "--secondStep": '0%'}, {opacity: 1, "--firstStep": '44.26%', "--secondStep": '100%'})
    .from('.header__logo', {scale: 0.3, opacity: 0},'<50%')
    .from('.header__menu', {scale: 0.3, opacity: 0},'<50%')
    .from('.header__social li', {scale: 0.3, opacity: 0},'<50%')


  gsap.to(".who-we__big-text span", {
    scrollTrigger: {
      trigger: '.who-we__big-text',
      start: 'top 95%',
      end: 'bottom 50%',
      scrub: 2
    },
    ease: "power1.out",
    backgroundPositionX: '-100%'
  })






gsap.from('.we-do__box', {
  scrollTrigger: {
    trigger: '.we-do__inner',
    start: 'top 80%'
  },
  stagger: 0.2,
  y: 100,
  opacity: 0
})

  window.innerWidth > 991 ? circle() : null

  gsap.to('.preloader', {
    duration: 1,
    autoAlpha: 0,
    onComplete: () => {
      headerTl.play(0)
    }
  })
  linearAnimations();

  validate('input[name="name"]', 'input[name="phone"]', 'input[name="email"]');
  send('#form', 'input[name="name"]', 'input[name="phone"]', 'input[name="email"]', "/mailer/mail-footer.php");
  
})