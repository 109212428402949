const circle = () => {
  gsap.registerPlugin(MotionPathPlugin)
  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(ScrollToPlugin)

  const pathLength = document.querySelector("#path").getTotalLength()

  gsap.set("#path, #path-blur", {
    strokeDasharray: pathLength,
    strokeDashoffset: pathLength
  })

  const itemsArr = gsap.utils.toArray('.services__step')
  const slidesArr = gsap.utils.toArray('.services__slide')
  let itemIndex = 0
  toggleActiveItem(0, true)

  itemsArr.forEach((item, i) => {
    gsap.set(item, {
      motionPath: {
        path: "#path",
        align: "#path",
        alignOrigin: [0.5, 0.5],
        end: 1 / 6 * i
      }
    })
    item.addEventListener('click', () => {
      let dif = circleSlider.end - circleSlider.start
      let scrollTo = circleSlider.start + (dif/6 * i) + 20
      gsap.to(window, { duration: 1, scrollTo: scrollTo });
    })
 })

 const circleSlider = ScrollTrigger.create({
   trigger: ".services__box",
   start: "center center",
   end: "+=1500px",
   pin: true,
   onUpdate: (self) => {
      let nextStage = (1 / 6)*(itemIndex + 1)
      let currentStage = (1 / 6)*itemIndex
    // console.log('next: ' + nextStage, 'current: '+ currentStage);
      // console.log(self.progress.toFixed(3));
      if (nextStage < self.progress.toFixed(3)) {
        itemIndex++
        toggleActiveItem(itemIndex, true)
      } else if (currentStage > self.progress.toFixed(3)) {
        itemIndex--
        toggleActiveItem(itemIndex, false)
      }
     gsap.set("#path, #path-blur", {
      strokeDashoffset: pathLength - (self.progress.toFixed(3) * pathLength),
      
     })
   },
 });


 function toggleActiveItem (index, addClass) {
  if (addClass) {
    itemsArr[index].classList.add('active')
  } else {
    itemsArr[index + 1].classList.remove('active')
  }

  slidesArr.forEach(item => {
    item.classList.remove('active')
  })
  slidesArr[index].classList.add('active')
 };

}



module.exports = circle;