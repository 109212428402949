const linearAnimations = () => {
    gsap.registerPlugin(ScrollTrigger);
    let mm = gsap.matchMedia();
    mm.add({
    isMobile: "(max-width: 576px)",
    isDesktop: "(min-width: 577px)"
    }, (context) => {
    let { isMobile, isDesktop } = context.conditions;
    if(isDesktop) {
        const tlTeamList = gsap.timeline({
            scrollTrigger: {
              trigger: ".team__list",
              start: "top 80%",
              end: "top 30%",
              toggleActions: "play none none reverse",
              scrub: true
            },
          });
          tlTeamList.from(".team__list>li:first-child", { y: 150}, "<0%")
                    .from(".team__list>li:nth-child(2)", {y: 150}, "<0%")
                    .from(".team__list>li:nth-child(3)", {y: 150}, "<0%")
                    .from(".team__list>li:nth-child(4)", {y: 150}, "<0%")
                    .from(".team__list>li:nth-child(5)", {y: 150}, "<100%")
                    .from(".team__list>li:nth-child(6)", {y: 150}, "<0%")
                    .from(".team__list>li:nth-child(7)", {y: 150}, "<0%")
                    .from(".team__list>li:nth-child(8)", {y: 150}, "<0%");   
    } else {
        const tlTeamList1 = gsap.timeline({
            scrollTrigger: {
              trigger: ".team__list>li:nth-child(2)",
              start: "top 95%",
              end: "top 40%",
              toggleActions: "play none none reverse",
              scrub: true
            },
          });
          tlTeamList1.from(".team__list>li:first-child", { y: 100}, "<0%")
                        .from(".team__list>li:nth-child(2)", {y: 100}, "<0%")

        const tlTeamList2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".team__list>li:nth-child(4)",
                start: "top 95%",
                end: "top 40%",
                toggleActions: "play none none reverse",
                scrub: true
            },
            });
            tlTeamList2.from(".team__list>li:nth-child(3)", { y: 100}, "<0%")
                        .from(".team__list>li:nth-child(4)", {y: 100}, "<0%")
        
        const tlTeamList3 = gsap.timeline({
            scrollTrigger: {
                trigger: ".team__list>li:nth-child(6)",
                start: "top 95%",
                end: "top 40%",
                toggleActions: "play none none reverse",
                scrub: true
            },
            });
            tlTeamList3.from(".team__list>li:nth-child(5)", { y: 100}, "<0%")
                        .from(".team__list>li:nth-child(6)", {y: 100}, "<0%")

        const tlTeamList4 = gsap.timeline({
            scrollTrigger: {
                trigger: ".team__list>li:nth-child(8)",
                start: "top 95%",
                end: "top 40%",
                toggleActions: "play none none reverse",
                scrub: true
            },
            });
            tlTeamList4.from(".team__list>li:nth-child(7)", { y: 100}, "<0%")
                        .from(".team__list>li:nth-child(8)", {y: 100}, "<0%")
        
    }

    const tlFormList = gsap.timeline({
        scrollTrigger: {
          trigger: ".form",
          start: "top 90%",
          end: isDesktop ? "top 50%" : "top: 15%",
          toggleActions: "play none none reverse",
          scrub: true
        },
    });

    tlFormList.from(".form__wrapper>input:nth-of-type(1)", { y: 150, opacity: 0}, "<0%")
                    .from(".form__wrapper>input:nth-of-type(2)", {y: 150, opacity: 0}, `${isDesktop ? "<0%" :"<100%"}`)
                    .from(".form__wrapper>input:nth-of-type(3)", {y: 150, opacity: 0}, `${isDesktop ? "<0%" :"<100%"}`)
                    .from(".form__wrapper>input:nth-of-type(4)", {y: 150, opacity: 0}, "<100%")
                    .from(".form__wrapper>input:nth-of-type(5)", {y: 150, opacity: 0}, `${isDesktop ? "<0%" :"<100%"}`)
                    .from(".form__wrapper>textarea", {y: 100, opacity: 0}, "30%");
    
  })
}

module.exports = linearAnimations;